* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Your Custom Font', sans-serif;
}

.sig {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  background-color: #0849f1;
  height: 100vh;
  overflow-y: hidden;
}

label {
  font-size: 22px;
}

table {
  width: 100%;
  max-width: 450px;
  background-color: white;
}
table.containertop {
  /* padding-top: 10px;
  padding-bottom: 10px;
  */
  display: block;
  height: 125px;
  max-height: 125px;

}

table.containerbottom {
  /* padding-top: 10px;
  padding-bottom: 10px;
  */
  max-height: 20px;
  height: 20px;
}

table.container.tr {
  height: 125px;
}

td.img-left {
  width: 25%;
}
td.img-right {

  width: 30%;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* Set the width to 30% */
}

td.content {
  width: 40%;
  /* Adjusted for a total of 100% (30% + 40% + 30%) */
  text-align: left;
  position: relative;

}

.tdcontenttable {
  height: 100px;
    border-right: 2px solid #167df3;
}

/* td.content::after {
  content: "";
  display: flex;
  width: 2px;

  background-color: #167df3;

  margin-top: 10px;
  height: 80%;
  position: absolute;
  top: 0;
  right: 0;
} */

img.pfp {
  margin: auto;
  width: 100px;
  border-radius: 50%;
  margin: 12px 6px;
}

img.secondary-logo {
  margin: auto;
  width: 100px;
  border-radius: 50%;
}

td.name {
  font-size: 16px;
  font-weight: bold;
  color: #167df3;
  text-transform: uppercase;
}

td.designation {
  font-size: 12px;
  color: #167df3;
  text-transform: uppercase;
}

td.contact {
  display: flex;
  font-size: 10px;
  color: #167df3;
  text-align: left;
}

td.contact img {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

table.company-container {
  width: 100%;

  height: 12px;
  padding: 10px;
  text-align: right;
  border-collapse: collapse;
  border-spacing: 0;
  background: linear-gradient(to right, #0037ff, #f0f0f0);
  color: white;
}

img.company-image {
  width: 30%;
  height: 20px;
  float: left;
}



.iconWrapper {
  display: flex;
  background-color: #0949f1;
  border-radius: 50%;
  width: 25px;
  margin: 3px;
  height: 25px;
  justify-content: center;
  align-content: center;
}


.iconWrapper2 {
  display: flex;
  background-color: #0949f1;
  border-radius: 50%;
  width: 20px;
  margin: 1px;
  height: 20px;
  justify-content: center;
  align-content: center;
}


.icon2 {
  margin: auto;
  color: #fff;
}



.contentFieldWrapper {
  text-align: center;
  margin: auto;
  margin-left: 2px;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  text-align: left;
}

.icon {
  height: auto;
  margin: auto;
  color: #fff;

}


td.icons {
  font-size: 8px;
  text-align: right;
  color: #167df3;
  white-space: nowrap;
}

td.icons a {
  text-decoration: none;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  color: #167df3;
  display: inline-block;

}


/*  */

#info {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-family: sans-serif, sans-serif;
  line-height: 13px;
}

#pfp {
  margin: 10px 10px 10px 10px;
  min-width: 60px;
  min-height: 60px;
  max-width: 100px;
  border-radius: 50%;
}

#details {
  margin: auto auto auto 0;

}

#contact {
  display: flex;
  flex-direction: row;
}

#contactDetail {
  margin-top: 2px;
  font-size: 1.25em;
  font-family: sans-serif;
  font-weight: 500;
}

#lineDiv {

  display: normal;
  margin: 1px 8px 0 8px;
  font-size: 1.25em;
  font-family: sans-serif;
  font-weight: 500;
}

#footer {
  background-color: black;
  display: flex;
  align-items: flex-end;
  height: 25%;
}

h1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 10px 0 10px 0;
}

h2 {
  color: #646464;
  font-weight: 600;
  line-height: 2px;
  font-family: sans-serif;
}

a {
  color: #0094FF;
}

#mk-logo {
  height: 15px;
  width: 100px;
  margin: auto auto auto 12px;
}

#tweet {
  margin: 0px 0px 0px 0px;
  height: 100%;
  max-height: 30px;
  width: 35px;
  float: right;
}

#discord {
  margin: 0px 0px 0px 0px;
  height: 100%;
  max-height: 30px;
  width: 35px;
  float: right;
}

#youtube {
  margin: 0px 0px 0.64px 0px;
  max-height: 30px;
  height: 100%;
  width: 35px;
  float: right;
}

@media all and (max-width: 460px) {
  #contact {
    flex-direction: column;
  }

  #lineDiv {
    display: none;
    margin: 1px 8px 0 8px;
    font-size: 1em;
    font-family: sans-serif;
    font-weight: 500;

  }
}

#logo-heading {
  width: 100%;
  max-height: 200px;
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

#form-heading {
  text-align: center;
  color: #fff;
  margin-top: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 6;

}

#forms {
  margin-top: 15px;
  line-height: 2;
  text-align: center;
}

#inputs {
  border-radius: 6px;
  margin-top: 20px;
  padding-left: 6px;
  min-height: 30px;
  min-width: 400px;
}


#inputs-file {
  border-radius: 6px;
  margin-top: 20px;
  min-height: 30px;
  min-width: 400px;
  color: white;
  margin: auto;

}

#submit-button {
  margin-top: 5px;
  padding: 5px 10px;
}
